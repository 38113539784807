import artsData from '../../../localhostDB/ArtsDB.json';
import entertainmentData from '../../../localhostDB/EntertainmentDB.json';
import sportsData from '../../../localhostDB/SportsDB.json';

const PortfolioAll = () => {
    return (
        <div className='OurPortfolio'>
            <div className="artsPage">
                <div className="container">
                    <div className="col-md-8 mx-auto">
                        <div className="text-center">
                            <div className="sectionHeader purpleLine" data-aos="fade-up" data-aos-delay="100">
                                <h2>Portfolio</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    {artsData.ArtsText.map((item, index) => (
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300" key={index}>
                            <div className="portfolio-thumb effect-3 Arts">
                                <div className="effect-img">
                                    <img src={item.portfolioThumb} alt={item.portfolioName} />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>{item.portfolioName}</h2>
                                        <p>{item.portfolioText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {entertainmentData.EntertainmentText.map((item, index) => (
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300" key={index}>
                            <div className="portfolio-thumb effect-3 Entertainment">
                                <div className="effect-img">
                                    <img src={item.portfolioThumb} alt={item.portfolioName} />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>{item.portfolioName}</h2>
                                        <p>{item.portfolioText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {sportsData.SportsText.map((item, index) => (
                        <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300" key={index}>
                            <div className="portfolio-thumb effect-3 sports">
                                <div className="effect-img">
                                    <img src={item.portfolioThumb} alt={item.portfolioName} />
                                </div>
                                <div className="portfolioOverlayBox effect-text">
                                    <div className="portfolioTet">
                                        <h2>{item.portfolioName}</h2>
                                        <p>{item.portfolioText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PortfolioAll