import React, { useState } from 'react'
import './HomeBanner.css';
import { Link } from 'react-router-dom';
import atomsImg from '../../../resources/images/art-home-Cover.jpg'
import entertainmentImg from '../../../resources/images/Entertainment-Home-Cover.jpg'
import sportsImg from '../../../resources/images/Sports-Home-Cover.jpg'

const HomeBanner = () => {
    const [atoms, setAtoms] = useState(3);
    const wrappers = document.querySelectorAll('.accordion-wrapper');
    const contents = document.querySelectorAll('.accordion-content');

    function reset() {
        wrappers.forEach((wrapper, i) => {
            wrapper?.classList.remove('activeAccordion');
        });
    }

    function activate(e: any) {
        if (e.target.matches('.accordion-aside')) {
            const i = e.target.dataset.aside;
            reset();
            wrappers[i]?.classList.add('activeAccordion');

            // Remove the 'd-none' class from the corresponding accordion content
            const $content = document.querySelector(`.accordion-content[data-aside="${i}"]`);
            if ($content) {
                $content.classList.remove('d-none');
            }

        }
        if (e.target.matches('.accordion-title')) {
            const i = e.target.dataset.aside;
            reset();
            wrappers[i]?.classList.add('activeAccordion');

            // Remove the 'd-none' class from the corresponding accordion content
            const $content = document.querySelector(`.accordion-content[data-aside="${i}"]`);
            if ($content) {
                $content.classList.remove('d-none');
            }
        }
    }

    const init = () => wrappers[0]?.classList.add('activeAccordion');

    window.addEventListener('load', init, false);
    document.addEventListener('click', activate, false);

    return (
        <div className='HomeBannerAll'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <div className="accordionSlider">
                            <div className={atoms === 1 ? 'accordion-wrapper activeAccordion' : 'accordion-wrapper'} onClick={() => setAtoms(1)}>
                                <div className="accordion-aside" data-aside={0}>
                                    <h2 className="accordion-title" data-aside={0}>Arts</h2>
                                </div>
                                <div className="accordion-content d-none" data-aside={0} style={{ background: `linear-gradient(271deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .7) 85%) fixed, url(${atomsImg}) center no-repeat` }}>
                                    <div className="accordion-content-block">
                                        <span data-aos="fade-up" data-aos-delay="100">arts</span>
                                        <h3 data-aos="fade-up" data-aos-delay="100">Discover a New <br /> Arts World</h3>
                                        <p data-aos="fade-up" data-aos-delay="200">Exciting new International content and local art activations showcasing famous and emerging talents from around the world and local Saudi artists.</p>
                                        <Link data-aos="fade-up" data-aos-delay="300" className='btn btn-danger' title="Learn More" to={'/arts#artsAboutUs'}>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={atoms === 2 ? 'accordion-wrapper activeAccordion' : 'accordion-wrapper'} onClick={() => setAtoms(2)}>
                                <div className="accordion-aside" data-aside={1}>
                                    <h2 className="accordion-title" data-aside={1}>Entertainment</h2>
                                </div>
                                <div className="accordion-content d-none" data-aside={1} style={{ background: `linear-gradient(271deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .7) 85%) fixed, url(${entertainmentImg}) center no-repeat` }}>
                                    <div className="accordion-content-block">
                                        <span data-aos="fade-up" data-aos-delay="100">entertainment</span>
                                        <h3 data-aos="fade-up" data-aos-delay="100">Creating Memorable <br /> Event Experiences</h3>
                                        <p data-aos="fade-up" data-aos-delay="200">Experts in hosting International entertainment activations alongside creating and developing amazing innovative local content experiences.</p>
                                        <Link data-aos="fade-up" data-aos-delay="300" className='btn btn-danger' to={'/entertainment#entertainmentAboutUs'}>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={atoms === 3 ? 'accordion-wrapper activeAccordion' : 'accordion-wrapper'} onClick={() => setAtoms(3)}>
                                <div className="accordion-aside" data-aside={2}>
                                    <h2 className="accordion-title" data-aside={2}>Sports</h2>
                                </div>
                                <div className="accordion-content" data-aside={2} style={{ background: `linear-gradient(271deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, .7) 85%) fixed, url(${sportsImg}) center no-repeat` }}>
                                    <div className="accordion-content-block">
                                        <span data-aos="fade-up" data-aos-delay="100">sports</span>
                                        <h3 data-aos="fade-up" data-aos-delay="100">Elevating Sports to <br /> New Levels</h3>
                                        <p data-aos="fade-up" data-aos-delay="200">Cosmos Sports KSA has established an elite team of world-renowned experts and exclusive partners for the Saudi sports market.</p>
                                        <Link data-aos="fade-up" data-aos-delay="300" className='btn btn-danger' to={'/sports#sportAboutUs'}>Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeBanner;