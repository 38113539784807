import React from 'react'
import '../resources/css/OurPartners.css';
import geaImg from '../resources/images/01_GEA-grey.png';
import necImg from '../resources/images/02_national-events.png';
import selaImg from '../resources/images/03_sela.png';
import lhImg from '../resources/images/04_ministry-of-culture-visual.png';
import ministryImg from '../resources/images/05_ministry-culture.png';

interface IProps {
    clientsLineCl: any;
}

const OurPartners = ({ clientsLineCl }: IProps) => {
    return (
        <section className='OurPartnersBox'>
            <div className="OurPartnersLogo">
                <div className="container">
                    <div className="row">
                        <div className="col-12" data-aos="fade-down">
                            <div className={`sectionHeader text-center ${clientsLineCl}`}>
                                <h2>Our Clients</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center OurPartnersLogoImg">
                        <div className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center" data-aos="fade-down" data-aos-delay="100">
                            <div className="OurPartnersLogoImgInner">
                                <img src={geaImg} className='img-fluid' alt="geaImg" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center" data-aos="fade-down" data-aos-delay="200">
                            <div className="OurPartnersLogoImgInner">
                                <img src={necImg} className='img-fluid' alt="necImg" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center" data-aos="fade-down" data-aos-delay="300">
                            <div className="OurPartnersLogoImgInner">
                                <img src={selaImg} className='img-fluid' alt="selaImg" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center" data-aos="fade-down" data-aos-delay="400">
                            <div className="OurPartnersLogoImgInner">
                                <img src={lhImg} className='img-fluid' alt="lhImg" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center" data-aos="fade-down" data-aos-delay="500">
                            <div className="OurPartnersLogoImgInner">
                                <img src={ministryImg} className='img-fluid' alt="lhImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
