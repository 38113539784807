import React from 'react'
import '../Banner/InnerBanner.css'
// import { Link } from 'react-router-dom';

interface IProps {
    bannerTitle: any;
    bannerTitleSubLine: any;
    bannerText: any;
    bannerLink: any;
    bannerLinkText: any;
    bannerImg: any;
    themeClass: any;
    bannerPageName: any;
}

const InnerBanner = ({ bannerTitle, bannerTitleSubLine, bannerText, bannerLink, bannerLinkText, bannerImg, themeClass, bannerPageName }: IProps) => {
    return (
        <div className={`innerBannerAll innerBannerImg ${themeClass}`} style={{ backgroundImage: `url(${bannerImg})` }}>
            <div className='container'>
                <div className="row">
                    <div className="col-md-6">
                        <div className="innerBannerText">
                            <span data-aos="fade-up">{bannerPageName}</span>
                            <h1 data-aos="fade-up">{bannerTitle}<br />{bannerTitleSubLine}</h1>
                            {!bannerText ? <><p data-aos="fade-up">{bannerText}</p></> : null}
                            {/* {bannerLinkText !== "" ? <><Link data-aos="fade-up" className='btn btn-danger' to={bannerLink}>{bannerLinkText}</Link></> : null} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InnerBanner
