import React from 'react'

const SingleBar = () => {
    return (
        <div className="OurPartnersText">
            <div className="container text-center" data-aos="flip-down" data-aos-delay="500">
                <h3>Unlock the Potential of the Kingdom of Saudi Arabia With Cosmos</h3>
            </div>
        </div>
    )
}

export default SingleBar
