import React from 'react'
import FooterLogo from '../../../resources/images/cosmos_logo.svg';
import { Link, useLocation } from 'react-router-dom';



const Footer = () => {
    let location = useLocation();
    const currentPath = location.pathname;


    return (
        <footer className='footerSection'>
            <div className='container'>
                <div className='row'>
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="footerInfo">
                            <div className="footerInfoLogo">
                                <img className='img-fluid' src={FooterLogo} alt="COSMOSKSA" />
                            </div>
                            <div className="footerInfoText">
                                <div className="footerInfoEmail">
                                    <ul>
                                        <li>
                                            <span className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.71 20.6" fill="currentColor"><path d="m0,6.87c0-1.89.67-3.5,2.02-4.85C3.36.67,4.98,0,6.87,0s3.5.67,4.85,2.02c1.34,1.34,2.01,2.96,1.99,4.85,0,.98-.14,1.78-.43,2.4l-4.87,10.37c-.14.29-.36.52-.65.7-.29.18-.58.26-.89.26s-.61-.09-.91-.26c-.3-.18-.51-.41-.62-.7L.43,9.27c-.29-.62-.43-1.42-.43-2.4Zm3.43,0c0,.94.34,1.75,1.01,2.42.67.67,1.48,1.01,2.42,1.01s1.75-.34,2.42-1.01c.67-.67,1.01-1.48,1.01-2.42s-.34-1.75-1.01-2.42c-.67-.67-1.48-1.01-2.42-1.01s-1.75.34-2.42,1.01c-.67.67-1.01,1.48-1.01,2.42Z" /></svg>
                                            </span>
                                            <p>Moon Tower <span>|</span> King Fahd Road <span>|</span> Riyadh <span>|</span> Kingdom of Saudi Arabia</p>
                                        </li>
                                        <li>
                                            <span className='icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.01 18.87" fill="currentColor"><path d="m0,16.71V6.07c.4.45.85.84,1.34,1.18,3.23,2.19,5.46,3.73,6.67,4.61.51.38.92.68,1.22.89.3.21.73.42,1.27.65.54.22,1.03.33,1.46.31h.05c.45,0,.94-.1,1.46-.31.53-.21.95-.42,1.27-.65.32-.22.73-.52,1.22-.89,1.52-1.09,3.75-2.62,6.67-4.61.51-.35.96-.74,1.34-1.18v10.63c0,.59-.21,1.1-.62,1.51-.42.42-.92.63-1.51.65H2.14c-.58,0-1.08-.22-1.51-.65C.19,17.79-.02,17.28,0,16.71ZM0,2.42C0,1.74.18,1.16.55.7c.37-.46.9-.7,1.58-.7h19.73c.58,0,1.07.21,1.49.62.42.42.63.92.65,1.51,0,.7-.22,1.38-.65,2.02-.43.64-.98,1.19-1.63,1.66-3.36,2.34-5.46,3.78-6.29,4.34l-1.27.94c-.19.14-.42.29-.7.43-.27.14-.54.26-.79.36-.26.1-.47.14-.65.12h-.05c-.19,0-.41-.04-.65-.12-.24-.08-.5-.2-.79-.36-.29-.16-.52-.3-.7-.43l-1.27-.94-3.53-2.42c-1.54-1.06-2.45-1.7-2.74-1.92-.56-.37-1.08-.88-1.56-1.54C.26,3.62.02,3,0,2.42Z" /></svg>
                                            </span>
                                            {currentPath === '/sports' ?
                                                <><p><a href="mailto:sports@cosmosksa.com">sports@cosmosksa.com</a></p></> :
                                                <><p><a href="mailto:business@cosmosksa.com">business@cosmosksa.com</a></p></>
                                            }


                                        </li>
                                        <li>
                                            <span className='icon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.87 18.87" fill="currentColor"><path d="m0,4.56C0,3.73.24,2.9.7,2.06c.5-.91.97-1.46,1.42-1.63.22-.1.53-.19.91-.29.38-.1.7-.14.94-.14.13,0,.22.02.29.05.16.05.4.39.72,1.03.1.16.22.4.38.72.16.32.32.6.48.84.16.24.3.48.41.72.03.03.11.14.24.34.13.19.22.35.29.48.06.13.1.26.1.38,0,.18-.13.39-.38.65-.26.26-.54.5-.84.74-.3.24-.58.48-.82.72-.24.24-.37.44-.38.6,0,.08.02.18.07.31l.1.26s.08.16.19.34c.11.18.17.26.17.24.67,1.23,1.45,2.29,2.33,3.17.88.88,1.93,1.66,3.14,2.33.02,0,.1.05.24.14.14.1.26.16.34.19l.26.12c.1.05.2.06.31.05.16,0,.36-.12.6-.36.24-.24.48-.52.72-.84.24-.32.49-.6.74-.84.26-.24.48-.37.67-.38.11,0,.23.03.36.1.13.06.29.16.48.29.19.13.3.21.34.24l2.3,1.3c.62.3.96.54,1.01.7.03.06.05.16.05.29,0,.24-.05.55-.14.94-.1.38-.19.69-.29.91-.18.45-.72.93-1.63,1.44-.83.45-1.66.67-2.47.67-.26,0-.5-.02-.72-.05-.22-.03-.48-.09-.77-.17-.29-.08-.5-.14-.62-.19l-1.42-.5c-.86-.32-1.64-.7-2.33-1.13-1.15-.7-2.34-1.66-3.55-2.88-1.22-1.22-2.18-2.4-2.88-3.55-.43-.69-.8-1.46-1.1-2.33l-.24-.67c-.13-.37-.22-.62-.29-.74-.06-.13-.13-.34-.19-.62l-.17-.77c-.05-.22-.06-.46-.05-.7Z" /></svg></span>
                                            <p><a href="tel:+966112733320">+966 11 273 3320</a></p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="socialMediaList">
                                    <ul>

                                        {currentPath === '/sports' ?
                                            <><li className='linkedinIcon'>
                                                <Link to="https://www.linkedin.com/company/cosmos-sports-ksa/" target="_blank" rel="noopener noreferrer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
                                                </Link>
                                            </li></> :
                                            <>
                                                <li className='facebookIcon'>
                                                    <Link to="https://www.facebook.com/Cosmos-KSA-108359701805521" target="_blank" rel="noopener noreferrer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                                                    </Link>
                                                </li>
                                                <li className='instagramIcon'>
                                                    <Link to="https://www.instagram.com/cosmos.ksa/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                                    </Link>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='clearfix'></div>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <span>©</span> 2019 to {new Date().getFullYear()} - <Link to="/" >cosmosksa.com</Link>
                </div>
            </div>
            <div className='footerLine'>
                <span>i</span>
            </div>
        </footer>
    )
}

export default Footer
