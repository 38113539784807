import React, { useEffect, useState } from 'react';
import '../resources/css/LetsConnectForm.css';
import axios from 'axios';

interface IProps {
  letsConnectText: any;
  formLineCl: any;
}

const LetsConnectForm = ({ letsConnectText, formLineCl }: IProps) => {

  const getPageDefaultInterest = () => {
    switch (window.location.pathname) {
      case '/arts':
        return 'Arts';
      case '/entertainment':
        return 'Entertainment';
      case '/sports':
        return 'Sports';
      default:
        return 'Please choose…';
    }
  };

  const getPageName = () => {
    switch (window.location.pathname) {
      case '/arts':
        return 'arts';
      case '/entertainment':
        return 'entertainment';
      case '/sports':
        return 'sports';
      default:
        return 'home';
    }
  };

  const initialValues = {
    full_name: '',
    company_name: '',
    to_email: '',
    mobile_number: '',
    interest: getPageDefaultInterest(), // Set the default interest dynamically
    message: '',
    page: getPageName()
  };

  // Create state variables to store form values and validation errors
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    // Validate the form
    const errors = validateForm(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post('https://cosmosksa.com/send_contact_us_mail.php', formValues, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.data.status === true) {
          setTimeout(() => {
            setLoading(false);
            setSubmitted(true);
            setFormValues(initialValues);
          }, 1000);
        }

      } catch (error) {
        setLoading(false);
      }

    } else {
      setLoading(false);
      setSubmitted(false);
    }
  };

  const getPageLabel = () => {
    switch (window.location.pathname) {
      case '/arts':
        return 'Arts';
      case '/entertainment':
        return 'Entertainment';
      case '/sports':
        return 'Sports';
      default:
        return 'Please choose…';
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0) {

    }
  });

  // Define validation functions for each field
  const validateForm = (values: any) => {
    const errors: any = {};

    if (!values.full_name) {
      errors.full_name = ' ';
    }

    if (!values.company_name) {
      errors.company_name = ' ';
    }

    if (!values.to_email) {
      errors.to_email = ' ';
    } else if (!isValidEmail(values.to_email)) {
      errors.to_email = ' ';
    }

    if (!values.mobile_number) {
      errors.mobile_number = ' ';
    }

    if (values.interest === getPageDefaultInterest()) {
      if (values.interest === 'Please choose…') {
        errors.interest = ' ';
      }
    }

    if (!values.message) {
      errors.message = ' ';
    }

    return errors;
  };

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    return regex.test(email);
  };

  return (
    <section className="letsConnectForm">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="text-center">
              <div className={`sectionHeader ${formLineCl}`} data-aos="fade-down" data-aos-delay="100">
                <h2>Let’s Connect</h2>
              </div>
              <p data-aos="fade-down" data-aos-delay="200">{letsConnectText}</p>
            </div>
            <div className="letsConnectFormBox" data-aos="fade-down" data-aos-delay="300">
              <form onSubmit={handleSubmit}>
                <div className={`mb-3 ${formErrors.full_name ? 'error_here' : ''}`}>
                  <label htmlFor="fullName" className="form-label"> Full name <span>*</span> </label>
                  <input
                    className="form-control"
                    type="text"
                    name="full_name"
                    id="full_name"
                    value={formValues.full_name}
                    onChange={handleChange}
                  />
                  {formErrors.full_name && (
                    <div className="error-message">{formErrors.full_name}</div>
                  )}
                </div>
                <div className={`mb-3 ${formErrors.company_name ? 'error_here' : ''}`}>
                  <label htmlFor="companyName" className="form-label"> Company Name <span>*</span> </label>
                  <input
                    className="form-control"
                    type="text"
                    name="company_name"
                    id="company_name"
                    value={formValues.company_name}
                    onChange={handleChange}
                  />
                  {formErrors.company_name && (
                    <div className="error-message">{formErrors.company_name}</div>
                  )}
                </div>
                <div className={`mb-3 ${formErrors.to_email ? 'error_here' : ''}`}>
                  <label htmlFor="email" className="form-label"> Email <span>*</span> </label>
                  <input
                    type="email"
                    name="to_email"
                    className="form-control"
                    id="to_email"
                    value={formValues.to_email}
                    onChange={handleChange}
                  />
                  {formErrors.to_email && (
                    <div className="error-message">{formErrors.to_email}</div>
                  )}
                </div>
                <div className={`mb-3 ${formErrors.mobile_number ? 'error_here' : ''}`}>
                  <label htmlFor="mobileNumber" className="form-label"> Mobile Number <span>*</span> </label>
                  <input
                    className="form-control"
                    type="number"
                    name="mobile_number"
                    id="mobile_number"
                    value={formValues.mobile_number}
                    onChange={handleChange}
                  />
                  {formErrors.mobile_number && (
                    <div className="error-message">{formErrors.mobile_number}</div>
                  )}
                </div>
                <div className={`mb-3 ${formErrors.interest ? 'error_here' : ''}`}>
                  <label htmlFor="interest" className="form-label"> Interest <span>*</span> </label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select"
                    name="interest"
                    value={formValues.interest}
                    onChange={handleChange}
                  >
                    <option value="">{getPageLabel()}</option>
                    <option value="arts">Arts</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="sports">Sports</option>
                  </select>
                  {formErrors.interest && (
                    <div className="error-message">{formErrors.interest}</div>
                  )}
                </div>
                <div className={`mb-3 mb-0 ${formErrors.message ? 'error_here' : ''}`}>
                  <label htmlFor="message" className="form-label"> Message <span>*</span> </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    value={formValues.message}
                    onChange={handleChange}
                  />
                  {formErrors.message && (
                    <div className="error-message">{formErrors.message}</div>
                  )}
                </div>
                <div className="text-end">
                  <button className="btn btn-primary" disabled={loading}>
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      <>Submit {' '}<span><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 7.8 13.37"><path d="m0,12.27c0-.11.05-.22.14-.31l5.26-5.26L.14,1.42c-.1-.08-.14-.18-.14-.29s.05-.22.14-.31L.82.14c.08-.1.18-.14.31-.14s.22.05.29.14l6.24,6.24c.1.08.14.18.14.31s-.05.23-.14.31L1.42,13.25c-.08.08-.18.12-.29.12s-.22-.04-.31-.12l-.67-.67c-.1-.1-.14-.2-.14-.31Z" /></svg></span></>
                    )}</button>
                </div>
                <div className='text-center'>
                  <div id="message-container">
                    {submitted ? <p>Thank you for your enquiry. We will be in touch shortly.</p> : null}
                  </div>
                </div>
                <div className='text-center'>
                  <div id="message-container"></div>
                  {loading && <p></p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsConnectForm;